exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-daddel-index-tsx": () => import("./../../../src/pages/projects/daddel/index.tsx" /* webpackChunkName: "component---src-pages-projects-daddel-index-tsx" */),
  "component---src-pages-projects-die-gruene-3-index-tsx": () => import("./../../../src/pages/projects/die-gruene-3/index.tsx" /* webpackChunkName: "component---src-pages-projects-die-gruene-3-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-nextcloud-google-docs-redirect-index-tsx": () => import("./../../../src/pages/projects/nextcloud-google-docs-redirect/index.tsx" /* webpackChunkName: "component---src-pages-projects-nextcloud-google-docs-redirect-index-tsx" */),
  "component---src-pages-projects-react-activity-calendar-index-tsx": () => import("./../../../src/pages/projects/react-activity-calendar/index.tsx" /* webpackChunkName: "component---src-pages-projects-react-activity-calendar-index-tsx" */),
  "component---src-pages-projects-zielgruppengerecht-index-tsx": () => import("./../../../src/pages/projects/zielgruppengerecht/index.tsx" /* webpackChunkName: "component---src-pages-projects-zielgruppengerecht-index-tsx" */),
  "component---src-pages-snippets-tsx": () => import("./../../../src/pages/snippets.tsx" /* webpackChunkName: "component---src-pages-snippets-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

